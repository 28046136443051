<template>
  <z-card class="multi-reservation-card" width="100%">
    <template #content>
      <h2>{{ $t("welcome.find_your_reservation_title") }}</h2>
      <div class="body-text">
        {{ $t("welcome.find_your_reservation_multiple_description") }}:
      </div>
      <z-card
        v-for="(reservation, index) of reservations"
        :key="index"
        shadow
        width="100%"
        data-cy="reservation-card"
        :title="reservation.hotel"
        @click="onSelectReservation(index)"
      >
        <template #content>
          <div class="reservation-item">
            <z-icon class="reservation-icon" color="primary">
              $assignment_rounded
            </z-icon>

            <div class="reservation-content">
              <div class="button-text">
                {{ getMainGuestName(reservation) }}
              </div>
              <div class="checkin-checkout-date">
                <span>{{ $t("welcome.reservation_number") }}: </span>
                <span>{{ reservation.reservationNumber }}</span>
              </div>
              <div class="checkin-checkout-date">
                <span>{{ $t("welcome.checkin") }}: </span>
                <span>{{ formatDate(reservation.checkinDate) }}</span>
              </div>
              <div class="checkin-checkout-date">
                <span>{{ $t("welcome.checkout") }}: </span>
                <span>{{ formatDate(reservation.checkoutDate) }}</span>
              </div>
            </div>

            <z-btn
              v-if="$vuetify.breakpoint.mdAndUp || reservationOpened === index"
              data-cy="select-reservation"
              class="select-button"
              :text="
                $vuetify.breakpoint.mdAndUp
                  ? $t('welcome.see_reservation')
                  : $t('welcome.select_reservation')
              "
              :primary="$vuetify.breakpoint.smAndDown"
              :small="$vuetify.breakpoint.mdAndUp"
              :rounded="$vuetify.breakpoint.mdAndUp"
              @click="$emit('select', reservation)"
            />
          </div>
        </template>
      </z-card>
      <z-btn
        class="back-btn"
        :text="$t('welcome.back')"
        @click="$emit('back')"
      />
    </template>
  </z-card>
</template>

<script>
import dayjs from "dayjs"
import find from "lodash.find"
import { ref } from "vue"

export default {
  props: {
    reservations: {
      type: Array,
      required: true,
    },
    hotel: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const reservationOpened = ref(null)
    const formatDate = (date) => {
      return dayjs(date.replace("Z", "")).format("DD/MM/YYYY")
    }
    const onSelectReservation = (index) => {
      reservationOpened.value = index
    }

    const getMainGuestName = (reservation) => {
      return find(reservation.guests, { masterGuest: true }).fullName
    }

    return {
      getMainGuestName,
      reservationOpened,
      formatDate,
      onSelectReservation,
    }
  },
}
</script>

<style lang="scss" scoped>
.multi-reservation-card {
  display: grid;
  row-gap: 20px;
  max-width: 525px;
  padding: 20px;

  h2 {
    text-align: center;
  }
}

.reservation-item {
  display: grid;
  gap: 10px;
  text-transform: uppercase;
  grid-template-columns: 40px 1fr min-content;
  align-items: center;
  margin-top: 10px;
}

.reservation-content {
  display: grid;
  row-gap: 8px;
}

.checkin-checkout-date {
  font-size: 11px;
}

.back-btn {
  width: min-content;
  justify-self: end;
}

@media screen and (max-width: 786px) {
  .reservation-item {
    grid-template-columns: 40px 1fr;

    .select-button {
      grid-column: 1 / -1;
      justify-self: end;
      width: 100%;
    }
  }

  .back-btn {
    width: 100%;
  }
}
</style>
