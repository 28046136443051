<template>
  <multi-reservation-card
    data-cy="multi-reservation-page"
    :reservations="availableReservations"
    :hotel="hotel"
    @select="$store.dispatch('search/selectReservation', $event)"
    @back="$store.dispatch('flow/reservation/previous')"
  />
</template>

<script>
import { useNamespacedState } from "vuex-composition-helpers/dist"

import MultiReservationCard from "./components/MultiReservationCard.vue"

export default {
  components: { MultiReservationCard },
  setup() {
    const { availableReservations } = useNamespacedState("search", [
      "availableReservations",
    ])
    const { hotel } = useNamespacedState("company", ["hotel"])

    return {
      availableReservations,
      hotel,
    }
  },
}
</script>
